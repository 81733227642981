
._f {
  display: flex;
  gap: 0.8em;
}

._ftop {
  display: flex;
  gap: 0.8em;
  align-items: flex-start;
}

._fc {
  display: flex;
  gap: 0.8em;
  justify-content: center;
}

._fr {
  display: flex;
  justify-content: flex-end;
}

._fspace {
  display: flex;
  justify-content: space-between;
}

._fm {
  display: flex;
  gap: 0.8em;
  align-items: center;
}

._fcc {
  display: flex;
  gap: 0.8em;
  justify-content: center;
  align-items: center;
}

._fv {
  display: flex;
  gap: 0.8em;
  flex-direction: column;
}

._fh {
  display: flex;
  gap: 0.8em;
  flex-direction: row;
}

._f100 {
  display: flex;
  gap: 0.8em;
  width: 100%;
  flex: 1 1 100%;
}
._fv._f100 {
  height: 100%;
}

._f50 {
  display: flex;
  gap: 0.8em;
  width: 50%;
  flex: 1 0 50%;

}

._fgap0 {
  display: flex;
  gap: 0em;
}

._fgap04 {
  display: flex;
  gap: 0.4em;
}

._fgap04 {
  display: flex;
  gap: 0.2em;
}

._fgap1 {
  display: flex;
  gap: 1em;
}

._fgap2 {
  display: flex;
  gap: 2em;
}

._fgap4 {
  display: flex;
  gap: 4em;
}

._fgrow {
  display: flex;
  flex-grow: 1;
}

._fnogrow {
  display: flex;
  flex-grow: 0;
}

._fwrap {
  display: flex;
  flex-wrap: wrap;
}

._fnowrap {
  display: flex;
  flex-wrap: nowrap;
}

._fshrink {
  display: flex;
  flex-shrink: 1;
}

._fnoshrink {
  display: flex;
  flex-shrink: 0;
}

._fright {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

._pointer {
  cursor: pointer;
}
._t08 {
  font-size: 0.8em;
}

._t12 {
  font-size: 1.2em;
}

._o08 {
  opacity: .8;
}

._o06 {
  opacity: .6;
}

._o04 {
  opacity: .4;
}

._o02 {
  opacity: .2;
}

.tnowrap {
  white-space: nowrap;
}
.ellipsis {
  max-width: 100%;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.video-js-container {
  position: relative;
  padding-bottom: 50px;
  /* Add space for the controls */
}

.video-js .vjs-control-bar {
  bottom: -40px !important;
  /* Ensure the control bar is at the bottom */
  position: absolute;
  opacity: 1 !important;
  z-index: 12 !important;
}
.xt_line .xt_fragment_play0 {
  opacity: 0;
}

.xt_line:hover .xt_fragment_play0 {
  opacity: 1;
}

.xt_fragment_play0 .x_player_control_play {
  opacity: 0.2;
}

.xt_fragment_play0:hover .x_player_control_play {
  opacity: 1;
}

.xt_line.active .xt_trans,
.xt_line.active .xt_trans .xt_fragment_play0,
.xt_line.active .xt_trans .x_player_control_play,
.xt_line.active .xt_time {
  opacity: 1;
}
.x_voicing_cut0 .x_voicing_cut0_icon_add {
  position: absolute;
  opacity: 0;
}

.x_voicing_cut0 .x_voicing_cut0_icon_cut {
  opacity: 0.3;
}

.x_voicing_cut0:hover .x_voicing_cut0_icon_cut {
  opacity: 0;
}

.x_voicing_cut0:hover .x_voicing_cut0_icon_add {
  opacity: 1;
}
._pd1 {
  padding: 1em;
}

._pd2 {
  padding: 2em;
}

._r04 {
  border-radius: 0.4em;
}

._r1 {
  border-radius: 1em;
}

._r2 {
  border-radius: 2em;
}
._hov0 ._hov {
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0.04, 0.98, 0.335)s;
}

._hov0:hover ._hov {
  opacity: 1;
}

._hov_op {
  transition: opacity cubic-bezier(0.6, 0.04, 0.98, 0.335)s;
  opacity: 0.6;
}

._hov_op:hover {
  opacity: 1;
}