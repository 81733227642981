:root {
  --black: black;
  --radius-1: .8em;
  --gold: #ffea4e;
  --hover-4: #0000000f;
  --txt2: #00000085;
  --radius-2: .4em;
  --g: #d69f67;
  --m: #ec7a7a;
  --p: #c47cee;
  --r: #75dfd4;
  --bg-shade: #0000000f;
  --borders: #0000001f;
}


.x_button.x_button--ghost {
  border-width: 1px;
  border-color: var(--borders);
  box-shadow: inset 0 0 0 1px var(--borders);
  color: var(--black);
  background-color: #002bff00;
}

.x_button.x_button--ghost:hover {
  border-color: var(--black);
  background-color: var(--bg-shade);
  color: var(--black);
}

.x_button.x_button--ghost.x_button--red {
  color: #f45656;
  background-color: #f4565600;
  border-color: #f45656;
  box-shadow: inset 0 0 0 1px #f45656b8;
}

.x_button.x_button--flat {
  color: var(--black);
  background-color: #002bff00;
  border-color: #002bff00;
  padding-left: .2em;
  padding-right: .2em;
}

.x_button.x_button--flat:hover {
  box-shadow: none;
  color: var(--black);
}

.x_button.x_button--notchanged {
  border-color: var(--blue);
  background-color: var(--white);
  box-shadow: inset 0 0 0 1px var(--blue);
  color: var(--blue);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  overflow: hidden;
}

.x_button.x_button--submit {
  width: 3em;
}

.x_button.x_button--xl {
  min-height: 3em;
  font-size: 1.4em;
}

.x_button.x_button--icon {
  padding-left: .6em;
  padding-right: .6em;
}

.x_button.x_button--xxl {
  font-size: 2em;
}

.x_button.x_button--sm {
  padding: .4em .6em;
  font-size: .9em;
}

.x_button.x_button--sm.x_button--ghost {
  box-shadow: inset 0 0 0 1px var(--borders);
}

.x_button.x_button--sm.x_button--ghost.inactive {
  color: #dbdbdb;
  border-style: none;
}

.x_button.locked {
  cursor: not-allowed;
}

.x_button.x_button--locked {
  border: 1px solid var(--bg-shade);
  background-color: var(--bg-shade);
  cursor: not-allowed;
}

.x_button.x_button--locked:hover {
  box-shadow: none;
}

.x_button.x_button--red {
  background-color: var(--des);
}

.x_button.x_button--red:hover {
  box-shadow: inset 0 0 0 2px #e60000;
}


.x_button_icon {
  margin-left: -.2em;
}

.x_button_icon.x_button_icon--solo {
  margin-left: -.4em;
  margin-right: -.4em;
}