.x_track--video::-webkit-scrollbar {
  width: 2em;
  height: 2em;
  cursor: ew-resize;
  background: #191b2b !important;
}

.x_track--video::-webkit-scrollbar-button {
  background: #191b2b !important;
}

.x_track--video::-webkit-scrollbar-thumb {
  border-radius: 0.3em;
  background: #4b4f6d !important;
  box-shadow: inset 0px 0px 2px #444444, inset 0px -2px 0px #444444 !important;
  border: 1px solid #444444;
  margin: 0.4em 0;
  cursor: ew-resize;
}

/* .x_track--video​::-webkit-scrollbar-thumb::before {
  color: green;
  content: '123';
} */


.x_track--video::-webkit-scrollbar-track-piece {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px -2px 4px #000000;
padding: 0.2em;
  height: 1.6em;
  cursor: ew-resize;
}

.x_track.x_track--video:not(.x_track--v) {
  box-shadow: inset 0px -43px 0px #000000;
  border-bottom: 7px solid #000000;
}