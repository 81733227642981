.c_menu::-webkit-scrollbar {
  width: 20px;
}

.c_menu::-webkit-scrollbar-track {
  background-color: transparent;
}

.c_menu::-webkit-scrollbar-thumb {
  background-color: #d4d0cd62;
  border-radius: 20px;
  border: 7px solid transparent;
  background-clip: content-box;
}

.c_menu::-webkit-scrollbar-thumb:hover {
  background-color: #d4d0cd62;
}