.xt_layout::-webkit-scrollbar,
.x_track:not(.x_track--video)::-webkit-scrollbar {
  width: 2em;
  height: 2em;
  cursor: ew-resize;
}

.xt_layout::-webkit-scrollbar-button,
.x_track:not(.x_track--video)::-webkit-scrollbar-button {
  background: #b7b7b7;
}

.xt_layout::-webkit-scrollbar-thumb,
.x_track:not(.x_track--video)::-webkit-scrollbar-thumb {
  border-radius: 0.3em;
  background: rgb(233, 233, 233);
  box-shadow: inset 0px 0px 2px #ededed, inset 0px -2px 0px #444444;
  border: 1px solid #444444;
  margin: 0.4em 0;
  cursor: ew-resize;
}

/* .x_track:not(.x_track--video)::-webkit-scrollbar-thumb::before {
  color: green;
  content: '123';
} */

/* ::-webkit-scrollbar-track-piece {
  background: rgb(183, 183, 183);
  box-shadow: inset 0px -2px 4px #989898;
  padding: 0.2em;
  height: 1.6em;
  cursor: ew-resize;
} */