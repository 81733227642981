

._mvhi,
._mvh,
._mh,
.mobile_vh_yes,
.mobile_vhi_yes,
.mobile_h_yes {
  display: none;
}



.react-waves {
margin: 0 !important;
padding: 0 !important;
width: 100%  !important;
}


div {
  user-select: none;
}


.x_track:hover .x_track_hover {
  opacity: 1;
}

.inact,
.x_track_fragment_drag {
  pointer-events: none;
}

.x_track_drag0 {
  /* border: 1px solid red; */
}

.x_track_drag0:hover .x_track_drag,
.x_track_drag0:hover .x_track_drag-handle,
.x_track_drag0:hover .x_track_fragment_drag {
  opacity: 1;
}
/* .x_track_fragment.active  */
.x_track_fragment.active .x_track_drag-handle,
.x_track_drag0:hover .x_track_drag-handle {
  background-color: #ff7606;
}
.x_track_drag0:hover .x_track_drag-handle {
  background-color: #ff7606;
}
.x_track_drag0:hover .x_track_drag-icon {
  transform: scale(1);
}

.x_track_fragment:hover .x_track_drag-handle {
  width: 1em;
  height: 1em;
}

.x_track_drag0:hover .x_track_drag-handle {
  width: 2em;
  height: 2em;
}

.App {
  height: 100svh;
  flex-direction: column;
  display: flex;
}

.App-translate {
  flex-direction: column;
  display: flex;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  /* max-width: 400px; */
  /* margin: 0 auto; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.next {
  position: relative;
  animation: pulsate 0.7s infinite;
}

@keyframes pulsate {
  0%, 100% {
    box-shadow: inset 0 0 0 3px #ff760602, 0 4px 12px -2px rgba(0, 0, 0, .2), 0 1px 3px -1px rgba(0, 0, 0, .56);
  }
  50% {
    box-shadow: inset 0 0 0 3px #ff7606, 0 4px 12px -2px rgba(0, 0, 0, .2), 0 1px 3px -1px rgba(0, 0, 0, .56);
  }
}


.x_time0 {
  /* align-items: flex-end; */
}

.x_time_tick {
  height: 100%;
  border-right: 1px solid rgba(124, 124, 124, 0.14);
  box-sizing: border-box;
  height: 1em;
}

.x_time0 > .x_time_tick.toodense {
  display: none;
}

.x_time0 > .x_time_tick:nth-child(10n) {
  display: block;
  border-right-color: rgba(114, 114, 114, 0.25);
  height: 1.7em;
  right: auto !important;
}

.x_time0 > .x_time_tick.toodense:nth-child(10n) {
  height: 1em;
  border-right: 1px solid rgba(136, 136, 136, 0.495);
}
.x_time0 > .x_time_tick:nth-child(100n) {
  display: block;
  border-right-color: rgba(167, 167, 167, 0.6);
  height: 2.4em;
  right: auto !important;
}
.x_time0 > .x_time_tick.toodense:nth-child(100n) {
  height: 1.7em;
  border-right-color: rgba(176, 176, 176, 0.682);
}

.x_track_start {
  opacity: 0 !important;
}
.x_track_fragment:hover .x_track_start {
  opacity: 1 !important;
}

.x_track_start_hor {
  opacity: 0 !important;
}
.x_track_fragment:hover .x_track_start_hor {
  opacity: 1 !important;
}

.x_track_cc:hover .x_track_edit {
  opacity: 1;
}


.x_track:not(.x_track--v):not(.x_track--video) {
  box-shadow: inset 0px -43px 0px #b7b7b7;
  border-bottom: 7px solid #b7b7b7;
}

.xt_layout {
  box-shadow: inset -43px 0px 0px #b7b7b7;
  border-right: 7px solid #b7b7b7;
}

.x_track_fragment.trimmed {
  /* border-right: 1px solid red; */
  /* box-shadow: 10px 0 0 0px red; */
}

@keyframes fadeBackground {
  from {
    /* box-shadow: inset 0px 0 0 10px yellow; */
    color: red;
  }
  to {
    /* box-shadow: inherit; */
    color: inherit;
  }
}

.changed_recently {
  animation: fadeBackground 2s forwards;
}

.selected {
  background-color: blue !important;
}

label {
  font-weight: normal;
  display: flex;
  align-items: center;
  column-gap: 0.4em;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.x_button--ghost,
.x_button--ghost .x_button_icon .t_icon {
  color: currentColor;
  border-color: currentColor;
}


.x_track_length0 {
  opacity: 0;
}

.x_track_length0.dragging,
.x_track_length0.dragging:hover {
  opacity: 1 !important;
}
.x_track_length0:hover {
  opacity: 1;
}
.x_track_length0.dragging .x_track_length,
.x_track_length0.dragging:hover .x_track_length {
  width: 1em !important;
  height: 100% !important;
  }

  .x_track_length0.x_track_length0--v.dragging .x_track_length,
  .x_track_length0.x_track_length0--v.dragging:hover .x_track_length {
    height: 1em !important;
    width: 100% !important;
}
.x_track_length0 .x_track_length {
  width: 0em;
  height: 100%;
  }

  .x_track_length0.x_track_length0--v .x_track_length {
    height: 0em;
    width: 100%;
  }

  .x_track_length0.x_track_length0--v .x_track_length {
    height: 0em;
}
.x_track_length0:hover .x_track_length {
  width: 1em;
}
.x_track_length0.x_track_length0--v:hover .x_track_length {
  height: 1em;
  width: 100%;
}

.nooverflow {
  overflow: hidden;
}

@media screen and (max-width: 1366px) {

  .x_track_drag0 .x_track_drag,
  .x_track_drag0 .x_track_drag-handle,
  .x_track_drag0 .x_track_fragment_drag {
    opacity: 1;
  }

  /* .x_track_fragment.active  */
  .x_track_fragment.active .x_track_drag-handle,
  .x_track_drag0 .x_track_drag-handle {
    background-color: #ff7606;
  }

  .x_track_drag0:hover .x_track_drag-handle {
    background-color: #ff7606;
  }

  .x_track_drag0 .x_track_drag-icon {
    transform: scale(1);
  }

  .x_track_fragment .x_track_drag-handle {
    width: 1em;
    height: 1em;
  }

  .x_track_drag0 .x_track_drag-handle {
    width: 2.4em;
      height: 2.4em;
  }

  .x_track_length0.dragging .x_track_length {
    width: 1em !important;
  }

}

@media screen and (max-width: 767px) {

  ._mvh,
  .mobile_vh_yes {
    display: block !important;
  }

  ._mvh,
  .mobile_h_yes {
    display: block !important;
  }
}


@media screen and (max-width: 991px) {

  ._mvh,
  .mobile_vh_yes {
    display: block !important;
  }

  ._mvh,
  .mobile_h_yes {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 479px) {

  ._mv,
  .mobile_v_yes {
    display: flex !important;
  }

  ._mv_,
  .mobile_v_no {
    display: none !important;
  }
}
