



input[type=range] {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

input[type=range]::-webkit-slider-runnable-track {
  background-color: #9d9d9d79;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

input[type=range][disabled]::-webkit-slider-runnable-track {
  border: 1px solid #9d9d9d79;
  background-color: transparent;
  opacity: 0.4;
}

input[type=range]::-moz-range-track {
  background-color: #9d9d9d79;
  height: 6px;
  border-radius: 3px;
  border: none;
}

input[type=range]::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

input[type=range]::-ms-fill-lower {
  background-color: #9d9d9d79;
  border-radius: 3px;
}

input[type=range]::-ms-fill-upper {
  background-color: #9d9d9d79;
  border-radius: 3px;
}

input[type=range]::-ms-tooltip {
  display: none;
  /* display and visibility only */
}

input[type=range]::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

input[type=range]:active::-moz-range-thumb {
  outline: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #ff7606;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

input[type=range][disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #9d9d9d79;
}

input[type=range]:active::-webkit-slider-thumb {
  outline: none;
}

input[type=range]::-ms-thumb {
  border-radius: 100%;
  background-color: #ff7606;
  height: 18px;
  width: 18px;
  border: none;
}

input[type=range]:active::-ms-thumb {
  border: none;
}
